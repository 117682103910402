@import 'placeholders';
@import 'colors';

#Contact{
    min-height: 50vh;
    .darkBtn{
        @include darkButton;
        white-space: nowrap;
        vertical-align: middle;
        img{
            filter: invert(1);
            height: 30px;
            display: inline-block;
            margin: 0;
            vertical-align: middle;
            top: -5px;
            position: relative;
        }
    }

}

#formCon{
    @include fullwidthBG(#00283d);
    text-align: center;
}
#mailSent {
    margin: 20px auto;
    position: relative;
    display: table;
    width: 85vw;
    max-width: 500px;
}

#contactForm {
    position: relative;
    padding: 50px 50px;
    margin: 0 auto;
    width: 90%;
    max-width: 1400px;
}

.contactFormTb {
    font-size: 18px;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #bbb;
    margin-bottom: 30px;
    color: #eee;
    font-family: var(--pFont)
}

#contactForm ::placeholder{
    color: #ccc !important;
}

#contactForm p {
    font-size: 18px;
    font-family: var(--pFont)
}

.textarea {
    font-size: 18px;
    background-color: transparent;
    border: 1px solid #ccc;
    color: #eee;
    resize: vertical;
    transition: background-color .3s;
    font-family: var(--pFont);
    &:not(:placeholder-shown){
        background-color: #215d73;
        
    }
}

#dshinweis{
    color: #fff;
    font-size: 16px !important;
    flex: 1 0 100%;
}



#sendMail {
    display: block;
    margin: 30px auto;
    padding: 25px 100px;
    font-size: 20px;
    background-color: $button_dark_color;
    color: #eee;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    transition: .3s;
    box-shadow: 0 0 8px -2px #0007;
    
    img{
        filter: invert(1);
        margin-left: 10px;
        vertical-align: middle;
        height: 40px;
        width: auto;
    }
}

#sendMail:hover {
    background-color: #000103;
}


@media (min-width: 1001px) {

    #Contact {
        padding: 20px 60px;
    }

    #contactForm {
        @include inlineFlex(center);
        line-height: 20px;
        margin: 0 auto;
        text-align: center;
        align-content: baseline;
        padding: 50px 50px;
        padding-top: 80px;
        min-height: 95vh;
        position: relative;
        row-gap: 20px !important;
    }

    .contactFormTb {
        padding: 10px 20px;
        margin: 5px 20px;
        margin-left: 30px;
        width: 40vw;
        font-size: 22px;
        max-width: 600px;

        &:nth-child(5) {
            flex-grow: 1;
            flex-basis: 1;
            max-width: 1250px;
            margin-top: 20px;
            font-size: 32px;
        }
    }

    .textarea {
        padding: 20px 20px;
        min-height: 300px;
        flex-basis: 100%;
        margin: 20px auto;
        max-width: 1250px;
    }
}


@media (max-width:1000px) {
    #Contact {
        padding: 0px 15px;
    }

    #contactForm {
        padding: 20px 5px;
        width: 100%;
        position: relative;

        p {
            margin-top: 15px;
        }
    
    }


    .contactFormTb {
        padding: 10px 10px;
        margin: 5px auto;
        width: 100%;
        margin-bottom: 40px;
        font-size: 16px !important;
        border-bottom: 1.5px solid #666;

    }

    #dshinweis{
        font-size: 14px !important;
    }


    .textarea {
        padding: 10px 15px;
        min-height: 150px;
        width: 100%;
        font-size: 14px;
        margin-top: 10px;
        overflow-y: auto;
    }

    #sendMail {
        padding: 20px 40px;
        font-size: 20px;
        text-align: center;
        span{
            display: none;
        }
        img{
            width: 50px;
            height: auto;
            margin: 0 20px;
        }
    }

}