@import 'colors';

@mixin fullwidthBG($bc-color){
    background-color: $bc-color;
        box-shadow: -15vw 0 0px 3px $bc-color,
        15vw 0 0px 3px $bc-color;
}

@mixin inlineFlex($pos){
    display: inline-flex;
    justify-content: $pos;
    flex-wrap: wrap;
    align-items: start;
}

@mixin darkButton{
    margin: 30px auto;
    display: table;
    position: relative;
    padding: 25px 60px;
    font-size: 20px;
    background-color: $button_dark_color;
    color: #eee;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    transition: .3s;
    box-shadow: 0 0 8px -2px #0007;
    text-align: center;
    text-decoration: none;
}

@mixin downloadBtn($bg, $fcolor) {
    padding: 10px 50px;
    text-align: center;
    line-height: 60px;
    color: $fcolor;
    font-size: 18px;
    text-decoration: none;
    transition: .3s;
    background-color: $bg;
    margin-top: 10px;
    border-radius: 3px;
    box-shadow: 0 0 8px -2px #0008;
    display: table;
}

@mixin autoWrapText{
    overflow-wrap: break-word;
        word-break: break-word;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
}