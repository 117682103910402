@media (min-width: 1001px) {

    #aktuelles {
        padding: 40px 60px;

    }

    #postsContainer{
        background-color: #eee;
        box-shadow: -20vw 0 #eee, 20vw 0 #eee;
        padding: 20px 40px;
        max-height: 800px;
        overflow-y: auto;
    }

    .post {
        margin: 30px 0;
        position: relative;
        padding-bottom: 20px;
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
        align-items: start;
    }

    .post::after{
        content: '';
        height: 2px;
        width: 100px;
        position: absolute;
        bottom: -5px;
        left: -30px;
        background-color: #999;
    }

    #aktuelles .post img{
        width: 300px;
    }

    .post .postTextSection{
        margin-left: 30px;
        margin-top: 10px;
        width: 75vw;
    }

    .postTextSection a:has(h2){
        color: #222;
        text-decoration: none;
        position: relative;
        transition: .3s;
        display: table;
        padding-bottom: 0;
    }

    .postTextSection a h2{
        margin-bottom: 0;
        padding: 0;
    }

    .postTextSection a:has(h2):hover{
        color: #179;   
    }

    .postTextSection .date{
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .postTextSection .description{
        width: 1200px;
    }
    

    .postLink{
        padding: 15px 30px;
        position: relative;
        display: table;
        border: 1px solid #000;
        transition: .3s;
        color: #245;
        text-decoration: none;
        background-color: transparent
    }

    .postLink:hover{
        background-color: #19b;
        color: #eee;
    }


}

@media (max-width: 1000px) {


    #formulare {
        padding: 0px 15px;
    }


}

@media (max-width: 1000px) {

    #aktuelles {
        padding: 0px 15px;

    }

    #postsContainer{
        background-color: #eee;
        box-shadow: -20vw 0 #eee, 20vw 0 #eee;
        padding: 10px;
    }

    .post {
        margin: 30px 0;
        padding-bottom: 25px;
        position: relative;
        text-align: left;
    }

    .post::after{
        content: '';
        height: 2px;
        width: 100px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: #999;
    }

    #aktuelles .post img{
        width: 90%;
        max-width: 500px;

    }

    .post .postTextSection{
        margin-top: 10px;
        max-width: 90vw;
        text-align: left;
    }

    .postTextSection a:has(h2){
        color: #222;
        text-decoration: none;
        position: relative;
        transition: .3s;
        display: table;
        padding-bottom: 0;
    }

    .postTextSection a h2{
        margin-bottom: 0;
        padding: 0;
        font-size: 20px;
    }

    .postTextSection a:has(h2):hover{
        color: #179;   
    }

    .postTextSection .date{
        position: absolute;
        bottom: 10px;
        right: 10px;
    }

    .postTextSection .description{
        width: 95%;
        font-size: 14px;
        line-height: 1.6em;
        margin-top: 5px;
    }
    

    .postLink{
        padding: 15px 30px;
        position: relative;
        display: table;
        border: 1px solid #000;
        transition: .3s;
        color: #245;
        text-decoration: none;
        background-color: transparent
    }

    .postLink:hover{
        background-color: #19b;
        color: #eee;
    }


}

@media (max-width: 1000px) {


    #formulare {
        padding: 0px 15px;
    }


}