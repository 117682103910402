@import url('./Components/components.css');

/* -- HEADER -- */
@media (min-width: 1000px) {
    #header {
        height: 80px;
        width: 100vw;
        background: #0D556E;
        padding: 0;
        top: 0;
        display: flex;
        justify-content: baseline;
        align-items: center;
        position: fixed;
        box-shadow: 0px 0px 18px -3px #0009;
        z-index: 11;
        
    }

    #header #toggleDropMenu,
    #header #toggleDropMenu input {
        display: none;
    }

    #logoLink {
        height: 60px;
        display: inline-block;
        color: #fff;
        text-decoration: none;
        
    }

    #logoLink span {
        color: #fff;
        font-size: 28px;
        position: relative;
        line-height: 60px;
        display: inline-block;
        transition: .5s ease-in-out;
        white-space: nowrap;
        max-width: 0px;
        overflow: hidden;
    }

    #logoLink:hover span{
        max-width: 300px;
    }

    #logoLink img {
        position: relative;
        height: 50px;
        margin: 5px 20px;
        background-color: #0D556E;
    }

    #logOutBtn{
        color: #900 !important;
        font-weight: bold;
        text-shadow: 0px 0px 10px #fff5;

    }

    #logOutBtn:hover {
        background-color: transparent !important;
    }

    #logOutBtn::before{
        background-color: #900 !important;
    }


    #header #headerRightSide {
        position: absolute;
        right: 0px;
    }

    #header #headerRightSide a {
        padding: 20px 40px;
        margin: 0px 25px 0 0;
        color: #fff;
        text-decoration: none;
        font-size: 18px;
        transition: .3s;
        position: relative;
        font-family: var(--pFont);
        letter-spacing: 1px;
    }

    #header #headerRightSide a:before {
        content: '';
        background-color: #fff;
        z-index: 1;
        position: absolute;
        bottom: 16px;
        width: 0px;
        border-radius: 99px;
        height: 2px;
        left: 50%;
        transform: translateX(-50%);
        transition: .3s;
    }

    #header #headerRightSide a:hover::before {
        width: 50%;
    }

    /* -- Footer -- */

    #footer {
        position: relative;
        bottom: 0;
        min-height: 300px;
        width: 100%;
        padding-bottom: 30px;
        background-color: #1D2A34;
    }

    #loggedInInfo{
        color: #ccc;
        background-color: #555;
        padding: 15px 10px;
        text-align: center;
        margin-bottom: 5px;
    }
    #loggedInInfo span{
        font-weight: 600;
    }

    #footer #socials {
        height: 60px;
        display: table;
        padding: 20px;
        margin: 0 auto;
        position: relative;
        border-bottom: 1px solid #fff;
        margin-bottom: 30px;
    }


    #footer #socials a {
        --hover-bc: #777;
        height: 60px;
        width: 60px;
        display: inline-block;
        border: 1px solid #fff;
        position: relative;
        box-shadow: inset 0 0 8px 2px #0002;
        margin: 0 20px;
        border-radius: 100px;
        transition: .4s;
    }

    #footer #socials a:hover {
        background-color: var(--hover-bc);
    }


    #footer #socials a i {
        font-size: 28px;
        color: #fff;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }

    #footerInline {
        display: block;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        text-align: center;
    }


    #footerImg {
        height: 250px;
    }

    
    #copyright {
        position: relative;
        text-align: center;
        margin-left: 50px;
        color: #ccc;
        margin-bottom: 40px;
    }

    #iconsP{
        color: #999;
        position: relative;
        top: 12px;
    }
    #iconsP a{
        text-decoration: none;
        opacity: .9;
    }
    
}

#footerBottom a {
    color: #eee;
    display: inline-block;
    text-align: center;
    margin: 0 10px;
    text-decoration: none;
    font-family: var(--pFont);

}

#footerInline hr{
    width: 90%;
    max-width: 550px;
    margin: 20px auto;
}

@media (max-width: 999px) {
    #header {
        height: 70px;
        width: 100vw;
        background-color: #0D556E;
        padding: 0;
        top: 0;
        display: flex;
        justify-content: baseline;
        align-items: center;
        position: fixed;
        box-shadow: 0 -7px 7px 10px #0008;
        z-index: 11;
    }

    #logoLink {
        height: 60px;
        display: inline-block;
    }

    #logoLink img {
        height: 50px;
        margin: 5px 20px;
    }

    #logoLink span {
        display: none;
    }

    #header #toggleDropMenu {
        color: #fff;
        position: absolute;
        top: 50%;
        right: 30px;
        font-size: 24px;
        transform: translateY(-50%);
        z-index: 2;
        cursor: pointer;
    }

    #header #toggleDropMenu input {
        appearance: none;
        -webkit-appearance: none
    }

    #header .dropMenu {
        width: 100vw;
        position: absolute;
        top: 69px;
        background-color: #0D556E;
        max-height: 0;
        transition: max-height .6s;
        overflow: hidden;
        z-index: -1;
    }

    .dropMenuActive {
        max-height: 400px !important;
        border-bottom-right-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
    }

    #logOutBtn{
        color: #a00 !important;
        font-weight: bold;
        text-shadow: 0px 0px 10px #fff5;

    }



    #header .dropMenu a {
        display: block;
        width: 100vw;
        padding: 25px 0px;
        margin: 0px 30px 0 0;
        color: #eee;
        text-decoration: none;
        font-size: 18px;
        transition: .3s;
        position: relative;
        text-align: center;
    }


    /* -- Footer -- */

    #footer {
        position: relative;
        bottom: 0;
        min-height: 300px;
        background-color: #1D2A34;
        width: 100vw;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    #loggedInInfo{
        color: #ccc;
        background-color: #555;
        padding: 15px 10px;
        text-align: center;
        margin-bottom: 5px;
        margin-top: -30px;
    }
    #loggedInInfo span{
        font-weight: 600;
    }
    
    #footer #socials {
        min-height: 60px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px 15px;
        padding: 15px 0;
        margin: 0 auto;
        position: relative;
    }


    #footer #socials::after {
        content: '';
        height: 1px;
        width: 300px;
        position: absolute;
        background-color: #eee;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
    }

    #footer #socials a {
        --hover-bc: #777;
        height: 60px;
        width: 60px;
        display: inline-block;
        border: 1px solid #fff;
        position: relative;
        box-shadow: inset 0 0 8px 2px #0007;
        margin: 0 0px;
        border-radius: 100px;
        transition: .4s;
    }

    #footer #socials a:focus {
        background-color: var(--hover-bc);
    }


    #footer #socials a i {
        font-size: 30px;
        color: #fff;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }

    #footerInline {
        width: 80vw;
        margin: 0 auto;
        text-align: center;
    }


    #footerImg {
        width: 80vw;
        max-width: 500px;
        position: relative;
        left: -5px;
        margin: 5px auto;
        margin-top: 20px;
        display: block;
    }


    #footerVerticalHr {
        width: 300px;
        height: 1px;
        margin: 20px auto;
        margin-bottom: 40px;
    }

    
    #copyright {
        position: relative;
        width: 100%;
        color: #ccc;
        text-align: center;
        padding: 0 10px;
    }

    #footerBottom {
        position: relative;
        margin: 10px auto;
        margin-bottom: 0;
        width: 100%;
    }

    #footerBottom span{
        display: none;
    }


    #footerBottom a {
        color: #ccc;
        display: block;
        text-align: center;
        margin-top: 5px;
        font-family: var(--pFont);
    }

    #iconsP{
        color: #999;
        position: relative;
        top: 12px;
        font-size: 14px;
    }
    #iconsP a{
        text-decoration: none;
        opacity: .9;
    }
}