#impressPage{
    padding: 20px 60px;
    margin-bottom: 50px;
}

#impressPage h2, p, strong, span{
    font-family: var(--pFont);
}


h2{
    margin-bottom: 20px;
}

#impressPage p{
margin: 0 20px;
font-size: 18px;
}

#impress{
    position: relative;
    max-width: 1200px;
}

#impress img{
    width: clamp(300px, 500px, 60%);
    height: auto;
    position: absolute;
    top: 50%;
    right: 00px;
    translate: 0 -50%;
    border-radius: 16px;
    background: linear-gradient(145deg, #d6d6d6, #ffffff);
    box-shadow:  5px 5px 10px #8a8a8a,
    -5px -5px 10px #ffffff;
}

strong{
    display: block;
    margin-block: 30px 5px;
    font-size: 18px;

}

strong:nth-child(2){
    margin-block: 10px 5px;
}

#disclaimer h3 {
    margin-block: 20px 10px;
    font-size: 20px;
    font-family: var(--pFont);
}

#disclaimer p{
    line-height: 1.3em;
    font-size: 18px;
    max-width: 1300px;
}


#ds h2 {
    margin-block: 30px 10px
}

#ds h3{
    margin-bottom: 5px;
    margin-left: 20px;
    font-family: var(--pFont);
    margin-top: 15px;
    font-size: 20px;
}

#ds h4{
    font-family: var(--pFont);
    margin-left: 25px;
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 2px;
}

#ds p{
    margin-left: 30px;
    font-size: 18px;
    text-align: justify;
    color: #555;

}

#ds ul{
    margin-left: 50px;
    margin-block: 10px;
}

#ds ul li{
    font-family: var(--pFont);
    font-size: 18px;
    margin-block: 5px;
    color: #555;

}


@media (max-width:1100px) {

    #impressPage{
        padding: 0 20px;
        margin-bottom: 50px;
    }

    #impress img{
        width: clamp(300px, 500px, 60%);
        height: auto;
        position: relative;
        margin: 0 auto;
        left: 15px;
        box-shadow: none;
        background: none;
        translate: 0 0 ;
    }

    #disclaimer p{
        text-align: left;
        color: #444;
    }

    #impressPage p{
        margin: 0 5px;
        font-size: 16px;
    }

    #impressPage h2{
        font-size: 20px;
        margin-bottom: 10px;
    }


    #ds h2 {
        margin-block: 10px 0px;
        font-size: 18px
    }
    
    #ds h3{
        margin-bottom: 5px;
        margin-left: 5px;
        font-family: var(--pFont);
        margin-top: 10px;
        font-size: 16px;
    }
    
    #ds h4{
        font-family: var(--pFont);
        margin-left: 7px;
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 2px;
    }
    
    #ds p{
        margin-left: 10px;
        font-size: 14px;
        text-align: justify;
        overflow-wrap: break-word;
        word-break: break-word;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
        color: #555;
    }
    
    #ds ul{
        margin-left: 25px;
        margin-block: 10px;
    }
    
    #ds ul li{
        font-family: var(--pFont);
        font-size: 14px;
        margin-block: 5px;
        color: #555;

    }
    
}