@import 'placeholders';
@import 'colors';

@media (min-width: 1001px) {
    #formsTable {
        --current-bc: #ccc;
        width: 100%;
        background-color: var(--current-bc);
        box-shadow: -10vw 0 0px 3px var(--current-bc),
        10vw 0 0px 3px var(--current-bc);        
        padding: 50px 30px;
    }

    #formulare {
        padding: 40px 60px;

    }

    .fileContainer {
        border-top: 1px solid #666;
        border-bottom: 1px solid #666;
        padding: 30px 10px;
        padding-bottom: 20px;
        position: relative;
        margin: 20px 0;
    }

    .focusedForm{
        scroll-margin: 200px;
        transition: background-color .6s;
        background-color: #0782;
    }

    .fileInfo {
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }

    .fileInfo img {
        height: 80px;
        position: relative;
        
    }

    .fileInfo div {
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        flex-basis: calc(100% - 100px);
        align-items: flex-start;

    }


    .fileContainer .fileName {
        font-weight: 700;
        font-family: var(--pFont);
        font-size: 18px;
        margin-top: 15px;
    }

    .fileContainer .fileDescription {
        position: relative;
        padding: 0px 2px;
        top: -5px;
        font-family: var(--pFont);
        flex-basis: 100%;
        color: var(--pFontColor);
        line-height: 1.4em;
    }

    .fileDate {
        position: absolute;
        top: 15px;
        right: 10px;
        color: #888
    }

    .downloadBtn {
        @include downloadBtn(rgb(46, 168, 182), #333);
        position: absolute;
        bottom: 15px;
        right:5px;
    }

    .downloadBtn:hover {
        background-color: #2d90a4;
    }


}

@media (max-width: 1000px) {

    #formsTable {
        width: 100%;
        box-shadow: 0 0 8px 2px #0004;
        padding: 20px 5px;
        max-height: 50vh;
        overflow-y: scroll;
    }

    #formsTable::-webkit-scrollbar{
        width: 2px;
    }

    #formulare {
        padding: 0px 15px;
    }


    .fileContainer::after {
        content: '';
        height: 1px;
        width: 50%;
        background-color: #666;
        position: absolute;
        left: 0%;
    }

    .fileContainer::after {
        bottom: 0;
    }

    .fileContainer {
        padding: 10px 10px;
        position: relative;

        .fileDate {
            position: absolute;
            bottom: 15px;
            color: #888;
            right: 10px;
        }
    }

    .focusedForm{
        scroll-margin: 200px;
        outline: 2px solid #078;
        outline-offset: -1px;
        border-radius: 3px;
    }

    .fileInfo {
        display: flex;
        flex-wrap: wrap;
        position: relative;
     img {
        height: 45px;
        position: relative;
        margin: 10px 2px;
        display: inline;
        flex: 0 .5 10%;
        }

    div {
        flex-basis: 75%;
        align-self: center;
        .fileName {
            font-weight: 700;
            font-family: var(--pFont);
            font-size: 14px;
        }

        .fileDescription {
            display: none;
        }
    }
}


    .downloadBtn {
        @include downloadBtn(#2ea8b6, #333);
        position: relative;
        margin: 10px auto;
        margin-top: 0;
        &:hover {
            background-color: #208c98;
        }
    }
    

}