@import 'placeholders';
@import 'colors';


@media (min-width: 1201px) {
    #about {
        padding: 40px 60px;
    }

    #currentOffers {
        max-width: 100%;
        margin: 0 0;
    }

    #currentOffers > h2 {
        margin: 20px 0;
        margin-bottom: 0px;
        font-size: 42px;
        padding-block: 12px;
        color: #eee;
        --current-bc: #274e81;
        background-color: var(--current-bc);
        box-shadow: -10vw 0 0px 3px var(--current-bc),
        10vw 0 0px 3px var(--current-bc);
    }

    .currentOffer {
        --current-bc: #c4caca;
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
        align-items: center;
        margin: 100px 0;
        background-color: var(--current-bc);
        margin-top: 00px;
        box-shadow: -10vw 0 0px 3px var(--current-bc),
                    10vw 0 0px 3px var(--current-bc);
        padding: 100px 10px;
        position: relative;
        min-height: 70vh;
        max-height: 100vh;
    }

    .currentOffer .currentOfferTextSection {
        padding: 10px 20px;
        display: flex;
        justify-content: left;
        align-items: center;
        flex-wrap: wrap;
        height: 90%;
    }

    .currentOffer img {
        height: clamp(200px,20vw, 300px);
        margin: 0px 10px;
    }

    .currentOffer div h2 {
        text-align: left;
        margin: 10px 0;
        margin-bottom: 30px;
        font-size: 28px;
        color: #333;
        font-family: var(--pFont);
        letter-spacing: 1.5px;
    }

    .currentOffer p {
        font-size: clamp(18px,1.3vw,20px) !important;
        text-align: justify;
        color: #555;
        font-family: var(--pFont);
        line-height: clamp(1.7em, 2vw, 2em) !important;
    }

    .currentOffer div a {
        @include darkButton;
    }

    .currentOffer div a:hover {
        background-color: #0c4357;
    }

}

@media (max-width: 1200px) {
    #about {
        padding: 0px 20px;
    }


    #currentOffers {
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
    }

    #currentOffers > h2 {
        --current-bc: #274e81;
        font-size: clamp(24px, 3vw, 36px);
        height: 50px;
        color: #eee;
        width: 100vw !important;
        padding-top: 10px;
        background-color: var(--current-bc);
        box-shadow: -10vw 0 0px 3px var(--current-bc),
        10vw 0 0px 3px var(--current-bc);
        z-index: 2;
        margin-bottom: 03;
    }

    .currentOffer {
        --current-bc: #c9cbcb;
        padding: 10px 0;
        padding-inline: 1vw;
        width: 100vw;
        position: relative;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        background-color: var(--current-bc);
        box-shadow: -10vw 0 0px 3px var(--current-bc),
        10vw 0 0px 3px var(--current-bc);
    }



    .currentOffer .currentOfferTextSection {
        padding: 10px 0px;
        display: flex;
        justify-content: left;
        align-items: center;
        flex-wrap: wrap;
        height: 90%;
        width: 100%;
        overflow: hidden;
        order: 0;

    }

    .currentOffer img {
        width: 90%;
        max-width: 200px;
        margin: 0px auto;
        display: none;
        order: 1;
    }

    .currentOffer div h2 {
        display: block;
        text-align: left;
        margin: 20px 0;
        margin-bottom: 10px;
        font-size: clamp(22px, 4vw, 32px);
        color: #333;
    }

    .currentOffer p {
        font-size: clamp(14px, 2vw, 18px);
        overflow-wrap: break-word;
        word-break: break-word;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
        color: #666;
        font-family: 'Open Sans',
            sans-serif;
        line-height: 1.7em;
    }

    .currentOffer div a {
        @include darkButton;

    }

    .currentOffer div a:hover {
        background-color: #0c4357;
    }

}