input[type="button"]{
    padding: 20px 30px;
    position: relative;
    display: table;
    border: 2px solid #2C9ab7; 
    color: #000;
    text-decoration: none;
    border-radius: 3px;
    transition: .3s;
    background-color: transparent;
    box-shadow: 0 0 5px 2px #0004;
    cursor: pointer;
    font-size: 18px;
    font-family: var(--pFont);
}


input[type="button"]:hover{
    background-color: #2C9ab7 !important;
    box-shadow: 0 0 5px 2px #0000;
    color: #eee;
}