.tbCon {
    position: relative;
    height: 55px;
    width: 300px;
    padding: 0;
    --bgColor: #444;
}

.tbCon input {
    position: absolute;
    inset: 0;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    font-size: 26px;
    color: #fff;
}

.tbCon label {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 24px;
    color: #ccc;
    transition: .3s;
    pointer-events: none;
    background-color: var(--bgColor);
    border-radius: 4px;
}

.tbCon input:focus~label,
.tbCon input:not(:placeholder-shown)~label {
    top: 0px;
    font-size: 16px;
    padding: 0 10px;
    border: 1px solid #ccc;
}

/* --- Submit-Button ---*/

.submitBtn {
    --btnBcColor: #1a9;
    padding: 0.8em 1.8em;
    border: 2px solid var(--btnBcColor);
    position: relative;
    overflow: hidden;
    background-color: transparent;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    transition: .3s;
    z-index: 1;
    font-family: inherit;
    color: var(--btnBcColor);
    cursor: pointer;
}

.submitBtn::before {
    content: '';
    width: 0;
    height: 300%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background: var(--btnBcColor);
    transition: .5s ease;
    display: block;
    z-index: -1;
}

.submitBtn:hover::before {
    width: 105%;
}

.submitBtn:hover {
    color: #111;
}