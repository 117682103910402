@media (min-width: 1001px) {
    #home{
        position: relative;
        height: auto;
        padding: 0;
        margin-top: -60px;
        
    }
    .typeWriter  {
        width: 100vw;
        position: relative;
        background-color: #05050500;
        height:auto;
        max-height: 100vh;
        overflow: hidden;
        display: block;
        box-shadow: 0 0 10px 2px #0009;
        margin: 0;
        margin-bottom: 0px;
    &-container {
        height: 100% !important;
        position: absolute;
        z-index: 1;
        text-align: center;
        inset: 0;
    }
}

      .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 160px;
        overflow: hidden;
        font-family: "Lato", sans-serif;
        font-size: clamp(42px, 4vw, 52px);
        line-height: 60px;
        color: #ecf0f1;
      }
      .content__container {
        font-weight: 600;
        overflow: hidden;
        height: 60px !important;
        padding: 0 60px;
        position: relative;
        width: clamp(900px, 90vw, 1100px);
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
        background-color: #0005;

		&::before {
		  content: "[";
		  left: 0;
		}

		&::after {
			content: "]";
			position: absolute;
			right: 0;
		}  
	
		&::after, &::before{
			position: absolute;
			top: -5px !important;
			color: #16a085;
			font-size: 58px;
			line-height: 60px;
			animation-name: opacity;
			animation-duration: 2s;
			animation-iteration-count: infinite;
		}


		&__text {
        margin: 0;
        position: relative;
      }

      	&__list {
			margin-top: 0;
			position: relative;
			margin-left: 40px;
			text-align: left;
			list-style: none;
			animation-name: change;
			animation-duration: 10s;
			animation-iteration-count: infinite;

			&__item{
				line-height: 60px;
				margin: 0;
			}
		}
      }


      @keyframes opacity {
        0%, 100% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
      }

      @keyframes change {
        0%, 12.66%, 100% {
          transform: translate3d(0, 0, 0);
        }
        16.66%, 29.32% {
          transform: translate3d(0, -60px, 0);
        }
        33.32%, 45.98% {
          transform: translate3d(0, -120px, 0);
        }
        49.98%, 62.64% {
          transform: translate3d(0, -180px, 0);
        }
        66.64%, 79.3% {
          transform: translate3d(0, -120px, 0);
        }
        83.3%, 95.96% {
          transform: translate3d(0, -60px, 0);
        }
      }
    #myVideo {
        position: relative;
        left: 0;
        width: 100% !important;
        height: auto;
        z-index: 1;
        opacity: 1;
        -webkit-filter: brightness(0.5);
        filter: brightness(0.6);
        top: -15%;
        aspect-ratio: 4/3;
    }


    #home div #header {
        position: relative !important;
    }

    #offersSection {
        margin: 0 auto;
        padding: 10px 50px;
        max-width: 1800px;
        color: #333;
        margin-bottom: 50px;
    h1 {
        padding: 2px 10px;
    }
}

    #offersSection>p {
        font-size: 20px;
        margin-left: 20px;
        line-height: 1.5em;
        margin-bottom: 80px;
        color: var(--pFontColor);
        font-family: var(--pFont);
    }

    #offers {
        --bc-color: #d8d8d8;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        gap: 20px 3vw;
        z-index: 2;

    .offer {
        width: 450px;
        /* border-top: 3px solid ; 
         border-bottom: 3px solid ; 
         border-image: linear-gradient(90deg, #4CaCf7,#00CED5, #4CaCf7); 
         border-image-slice: 2; */
        text-align: center;
        padding: 40px 10px;
        position: relative;
        z-index: 3;

		img {
			width: 200px;
			margin-bottom: 20px;
		}
	
		h2 {
			text-align: center;
			letter-spacing: .5px;
			margin-bottom: 10px;
			font-size: 20px;
			font-family: var(--pFont);
		}
	
		p {
			text-align: left;
			font-size: 15px;
			margin: 10px 5px;
			color: #666;
			font-family: var(--pFont);
			line-height: 1.6em;
			overflow-wrap: break-word;
			word-wrap: break-word;
	
			/* Adds a hyphen where the word breaks */
			-webkit-hyphens: auto;
			-ms-hyphens: auto;
			-moz-hyphens: auto;
			hyphens: auto;
	
		}
	
		a {
			padding: 20px 30px;
			position: relative;
			display: table;
			margin: 30px auto;
			 border: 2px solid #2C9ab7; 
			color: #000;
			text-decoration: none;
			border-radius: 3px;
			transition: .3s;
			background-color: transparent;
			box-shadow: 0 0 5px 2px #0004;
	
			&:hover{
				background-color: #2C9ab7 !important;
				box-shadow: 0 0 5px 2px #0000;
				color: #eee;
			}
		}	
    }
}


    #followUs{
            background: linear-gradient(145deg,#d1d1d1,#f8f8f8);
            border-radius: 14px;
            box-shadow: 8px 8px 16px #c5c5c5, -8px -8px 16px #fff;
            height: 500px;
            margin: 100px auto 20px;
            padding: 20px 50px;
            position: relative;
            text-align: center;
            width: 900px;        
    h1{
        text-align: left;
        margin-bottom: 55px;
    }
	 a{
        border: 1px solid #000;
        color: #111;
        display: inline-block;
        font-family: var(--pFont);
        font-size: 20px;
        font-weight: 700;
        margin: 1px 40px 40px;
        overflow: hidden;
        padding-block: 35px;
        position: relative;
        text-align: center;
        text-decoration: none;
        width: 230px;

		&::before{
			background-color: var(--hover-bc);
			border-bottom-right-radius: 2px;
			border-top-right-radius: 2px;
			content: "";
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			transition: .3s;
			width: 4px;
		}

		i{
			font-size: 32px;
			position: absolute;
			left: -30%;
			top: 50%;
			transform: translate(-50%, -50%);
			color: #eee;
			transition: .3s;
		}

		&:hover::before{
			width: 100%;
		}

		&:hover i{
			left: 50%;
		}
	
    }
	}
}


/*------------------Type-writer-------------*/
@media (max-width: 1000px) {
    .typeWriter {
        width: 100vw;
        aspect-ratio: 4/3;
        position: relative;
        height: auto;
        background-color: #05050500;
        overflow: hidden;
        box-shadow: 0 0 10px 2px #0009;
        margin: 0;
        z-index: 1;
        margin-bottom: 10px;
    }

    .typewriter-container {
        height: 100% !important;
        position: absolute;
        z-index: 2;
        text-align: center;
        inset: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -20%);
      height: 160px;
      overflow: hidden;
      font-family: "Lato", sans-serif;
      font-size: clamp(18px, 4vw, 38px);
      line-height: 60px;
      color: #ecf0f1;
    }
    .content__container {
      font-weight: 600;
      overflow: hidden;
      height: 60px !important;
      padding: 0 10px;
      position: relative;
      width: clamp(300px, 95vw, 850px);
      display: flex;
      justify-content: start;
      flex-wrap: nowrap;
      background-color: #0005;

    }
    .content__container:before {
      content: "[";
      left: 0;
    }
    .content__container:after {
      content: "]";
      position: absolute;
      right: 0;
    }
    .content__container:after, .content__container:before {
      position: absolute;
      top: 0px !important;
      color: #16a085;
      font-size: clamp(28px, 4vw, 52px);
      line-height: 60px;
      animation-name: opacity;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }
    .content__container__text {
      margin: 0;
      text-align: left;
      position: relative;
      z-index: 111;
      white-space: nowrap;
    }
    .content__container__list {
      margin-top: 0;
      position: relative;
      margin-left: clamp(0px, .5vw, 20px);
      text-align: left;
      list-style: none;
      animation-name: change;
      animation-duration: 10s;
      animation-iteration-count: infinite;
    }
    .content__container__list__item {
      line-height: 60px !important;
      margin: 0;
    }
    
    @keyframes opacity {
      0%, 100% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
    }

    @keyframes change {
      0%, 12.66%, 100% {
        transform: translate3d(0, 0, 0);
      }
      16.66%, 29.32% {
        transform: translate3d(0, -60px, 0);
      }
      33.32%, 45.98% {
        transform: translate3d(0, -120px, 0);
      }
      49.98%, 62.64% {
        transform: translate3d(0, -180px, 0);
      }
      66.64%, 79.3% {
        transform: translate3d(0, -120px, 0);
      }
      83.3%, 95.96% {
        transform: translate3d(0, -60px, 0);
      }
    }

    #myVideo {
        position: relative;
        left: -12%;
        width: 115% !important;
        z-index: 1;
        opacity: 1;
        -webkit-filter: brightness(0.6);
        filter: brightness(0.6);
    }


    #offersSection {
        margin: 0 auto;
        padding: 10px 20px;
        width: 100vw;
        margin-bottom: 50px;
    }

    #offersSection>p {
        font-size: 18px;
        line-height: 1.5em;
        margin-bottom: 80px;
        color: #444;
        font-family: var(--pFont);
        line-height: 1.4em;
        overflow-wrap: break-word;
        word-wrap: break-word;

        /* Adds a hyphen where the word breaks */
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
    }

    #offers {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px 60px;
    }

    #offersSection h1{
        padding-bottom: 15px;
        margin-top: 20px;
        position: relative;
    }

    .offer {
        position: relative;
        width: 92vw;
        max-width: 400px;
        border-top: 3px solid ;
        border-image: linear-gradient(90deg, #2C7CD7,#00CED5);
        border-image-slice: 2;
        text-align: center;
        padding: 30px 10px;
    }

    .offer img {
        width: 70%;
        margin-bottom: 20px;
    }

    .offer h2 {
        text-align: center;
        letter-spacing: .5px;
        margin-bottom: 10px;
    }

    .offer p {
        text-align: left;
        font-size: 16px;
        overflow-wrap: break-word !important;
        word-wrap: break-word !important;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
        margin: 20px 2px;
        margin-bottom: 35px;
        color: #444;
        font-family: var(--pFont);
        line-height: 1.4em;

    }

    .offer a {
        padding: 20px 30px;
        position: relative;
        display: table;
        margin: 10px auto;
         border: 2px solid #2C9ab7; 
        color: #000;
        text-decoration: none;
        border-radius: 3px;
        transition: .3s;
        background-color: transparent;
        box-shadow: 0 0 5px 2px #0004;

    }

    .offer a:hover {
        background-color: #1bb;
    }

    #followUs{
        padding: 20px 10px;
        position: relative;
        margin: 20px auto;
        margin-top: 100px;
        text-align: center;  
        width: 100%;
        min-height: 300px;
        border-radius: 14px;
        background: linear-gradient(145deg, #d1d1d1, #f8f8f8);
        box-shadow:  8px 8px 16px #c5c5c5,
                     -8px -8px 16px #ffffff; 
    }

    #followUs h1{
        text-align: left;
        font-family: var(--pFont);
        font-size: 36px ;
        margin-left: 10px;
    }


    #followUs a{
        position: relative;
        display: inline-block;
        margin-inline: clamp(10px, 2vw, 50px);
        margin-bottom: 20px;
        padding-block: 28px;
        width: clamp(120px, 30vw, 200px);
        text-align: center;
        border: 1px solid #000;
        color: #222;
        text-decoration: none;
        font-size: clamp(14px, 3vw, 20px);
        font-family: var(--pFont);
        font-weight: bold;
        overflow: hidden;
    }

    #followUs a::before{
        content: '';
        position: absolute;
        height: 100%;
        width: 4px;
        top: 0;
        left: 0;
        background-color: var(--hover-bc);
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;
        transition: .3s;

    }

    #followUs a i{
        font-size: 32px;
        position: absolute;
        left: -30%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #eee;
        transition: .3s;
    }

    #followUs a:hover::before{
        width: 100%;
    }

    #followUs a:hover i{
        left: 50%;
    }
}