.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: .5px;
  scroll-behavior: smooth;
}

html {
  width: 100vw;
  overflow-x: hidden;
}

body {
  background-color: #e8e8e8;
  width: 100vw !important;
  overflow-x: hidden !important;
  overflow-y: hidden;
}

.btn{
  border: 2px solid;
  border-image-source: linear-gradient(90deg, #4C8CE7 , #00aEb5 , #4C8CE7 );
  border-image-slice: .6;
}

.btn:hover{
  background-color: #4CaCf7 !important;

}

p a{
  color: #16a;
  font-weight: 600;
  font-family: var(--pFont);
}

#page {
  padding: 40px 0px;
  padding-top: 60px;
  width: 100% !important;
  margin: 0 !important;
  overflow-x: hidden !important;
  position: relative;
}

:root {
  --pFont: 'Montserrat';
  --pFontColor: #666;
  --titleFontColor: #333;
}

::-webkit-scrollbar {
  background-color: #ccc;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #222;
  border-radius: 99px;
}

body::-webkit-scrollbar{
  display: none;
}

.pageTitle {
  --bc-gradient: linear-gradient(90deg, #0f6987
  ,#08abb1) ;
  margin: 40px 0px;
  margin-top: 20px;
  font-size: 72px;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: var(--pFont);
  color: transparent;
  background: var(--bc-gradient);
  display: table;
  background-clip: text;
  -webkit-background-clip: text;
  position: relative;
  transition: color .3s !important;
  transition-delay: .3s;
  z-index: 2;
  padding: 5px 20px;
  padding-top: 10px;
}

.pageTitle::after{
  content: "";
  height: 5px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: var(--bc-gradient);
  position: absolute;
  border-radius: 5px;
  transition: .3s;
  z-index: -1;
}
.pageTitle:hover::after{
  height: 100%;
}

.pageTitle:hover{
  color: #eee;
}

.pageDescription {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 50px;
  color: #555;
  font-family: var(--pFont);
  line-height: 1.4em;
}

@media (max-width: 1000px) {
  .pageTitle {
    font-size: clamp(24px, 6vw, 48px) !important;
    text-align: left;
    padding: 10px 5px !important;
    padding-right: 15px !important;
    padding-top: 15px !important;
    max-width: 85vw;
    margin-top: 30px;
    line-height: .8em;
    margin-bottom: 20px;
    left: -5px;
  }

  .pageDescription {
    font-size: 16px !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    color: #666 !important;
    font-family: 'Montserrat';
    line-height: 1.3em !important;
  }
}