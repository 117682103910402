#PostTemplate{
    padding: 40px 60px;
}
.ImageContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px 40px;
    width: 90vw;
    margin: 0 auto;
}

.ImageContainer img{
    height: 500px;
}

@media screen and (max-width: 1000px) {
    #PostTemplate{
        padding: 0px 15px;
    }
    .ImageContainer {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px 40px;
        width: 90vw;
        margin: 0 auto;
    }
    

    h1{
        font-size: 36px !important
        ;
    }

    .ImageContainer img{
        width: 90vw;
        height: auto;
    }
    
}